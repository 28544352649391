export const dummyData = [
  {
    categoryName: "glasses",
    type: [
      {
        view: "3d",
        data: [
          {
            skuid: "cdc39de5-2dde-44a2-96fd-c93ab78ab87e",
            categoryName: "glasses",
            assetType: "3d",
            assetIcon:
              "https://cdn.pixelbin.io/v2/glamar-fynd-835885/6Dzoxo/original/cxr_assets/icons/glasses/09.jpg",
            assets: [
              "https://cdn.pixelbin.io/v2/glamar-fynd-835885/6Dzoxo/original/cxr_assets/glasses/07.gltf",
            ],
            created_at: "2023-10-19T13:40:04.279Z",
            updated_at: "2023-10-19T13:40:04.279Z",
          },
          {
            skuid: "0b731c6f-c09d-4b37-a582-c782b198d210",
            categoryName: "glasses",
            assetType: "3d",
            assetIcon:
              "https://cdn.pixelbin.io/v2/glamar-fynd-835885/6Dzoxo/original/cxr_assets/icons/glasses/10.jpg",
            assets: [
              "https://cdn.pixelbin.io/v2/glamar-fynd-835885/6Dzoxo/original/cxr_assets/glasses/06.gltf",
            ],
            created_at: "2023-10-19T13:43:23.870Z",
            updated_at: "2023-10-19T13:43:23.870Z",
          },
        ],
      },
      {
        view: "2d",
        data: [
          {
            skuid: "50436a92-88b6-4f99-863e-ad81520153d4",
            categoryName: "glasses",
            assetType: "2d",
            assetIcon:
              "https://cdn.pixelbin.io/v2/glamar-fynd-835885/6Dzoxo/original/cxr_assets/icons/glasses/07.jpg",
            assets: [
              "https://cdn.pixelbin.io/v2/glamar-fynd-835885/6Dzoxo/original/cxr_assets/2d/glasses/02/02_1.png",
              "https://cdn.pixelbin.io/v2/glamar-fynd-835885/6Dzoxo/original/cxr_assets/2d/glasses/02/02_2.png",
              "https://cdn.pixelbin.io/v2/glamar-fynd-835885/6Dzoxo/original/cxr_assets/2d/glasses/02/02_3.png",
              "https://cdn.pixelbin.io/v2/glamar-fynd-835885/6Dzoxo/original/cxr_assets/2d/glasses/02/02_4.png",
              "https://cdn.pixelbin.io/v2/glamar-fynd-835885/6Dzoxo/original/cxr_assets/2d/glasses/02/02_5.png",
              "https://cdn.pixelbin.io/v2/glamar-fynd-835885/6Dzoxo/original/cxr_assets/2d/glasses/02/02_6.png",
            ],
            created_at: "2023-10-19T13:44:45.316Z",
            updated_at: "2023-10-19T13:44:45.316Z",
          },
          {
            skuid: "92c423b1-9163-4083-aafa-db5fa1270b68",
            categoryName: "glasses",
            assetType: "2d",
            assetIcon:
              "https://cdn.pixelbin.io/v2/glamar-fynd-835885/6Dzoxo/original/cxr_assets/icons/glasses/08.jpg",
            assets: [
              "https://cdn.pixelbin.io/v2/glamar-fynd-835885/6Dzoxo/original/cxr_assets/2d/glasses/03/03_1.png",
              "https://cdn.pixelbin.io/v2/glamar-fynd-835885/6Dzoxo/original/cxr_assets/2d/glasses/03/03_2.png",
              "https://cdn.pixelbin.io/v2/glamar-fynd-835885/6Dzoxo/original/cxr_assets/2d/glasses/03/03_3.png",
              "https://cdn.pixelbin.io/v2/glamar-fynd-835885/6Dzoxo/original/cxr_assets/2d/glasses/03/03_4.png",
              "https://cdn.pixelbin.io/v2/glamar-fynd-835885/6Dzoxo/original/cxr_assets/2d/glasses/03/03_5.png",
              "https://cdn.pixelbin.io/v2/glamar-fynd-835885/6Dzoxo/original/cxr_assets/2d/glasses/03/03_6.png",
            ],
            created_at: "2023-10-19T13:44:45.316Z",
            updated_at: "2023-10-19T13:44:45.316Z",
          },
          {
            skuid: "28aa190a-b22e-453c-9538-babae7e86b15",
            categoryName: "glasses",
            assetType: "2d",
            assetIcon:
              "https://cdn.pixelbin.io/v2/glamar-fynd-835885/6Dzoxo/original/cxr_assets/icons/glasses/11.jpg",
            assets: [
              "https://cdn.pixelbin.io/v2/glamar-fynd-835885/6Dzoxo/original/cxr_assets/2d/glasses/11/11_1.png",
              "https://cdn.pixelbin.io/v2/glamar-fynd-835885/6Dzoxo/original/cxr_assets/2d/glasses/11/11_2.png",
              "https://cdn.pixelbin.io/v2/glamar-fynd-835885/6Dzoxo/original/cxr_assets/2d/glasses/11/11_3.png",
              "https://cdn.pixelbin.io/v2/glamar-fynd-835885/6Dzoxo/original/cxr_assets/2d/glasses/11/11_4.png",
              "https://cdn.pixelbin.io/v2/glamar-fynd-835885/6Dzoxo/original/cxr_assets/2d/glasses/11/11_5.png",
              "https://cdn.pixelbin.io/v2/glamar-fynd-835885/6Dzoxo/original/cxr_assets/2d/glasses/11/11_6.png",
            ],
            created_at: "2023-10-19T13:44:45.316Z",
            updated_at: "2023-10-19T13:44:45.316Z",
          },
          {
            skuid: "042cbaf3-b539-4883-902e-daa35fa78cb9",
            categoryName: "glasses",
            assetType: "2d",
            assetIcon:
              "https://cdn.pixelbin.io/v2/glamar-fynd-835885/6Dzoxo/original/cxr_assets/icons/glasses/13.jpg",
            assets: [
              "https://cdn.pixelbin.io/v2/glamar-fynd-835885/6Dzoxo/original/cxr_assets/2d/glasses/13/13_1.png",
              "https://cdn.pixelbin.io/v2/glamar-fynd-835885/6Dzoxo/original/cxr_assets/2d/glasses/13/13_2.png",
              "https://cdn.pixelbin.io/v2/glamar-fynd-835885/6Dzoxo/original/cxr_assets/2d/glasses/13/13_3.png",
              "https://cdn.pixelbin.io/v2/glamar-fynd-835885/6Dzoxo/original/cxr_assets/2d/glasses/13/13_4.png",
              "https://cdn.pixelbin.io/v2/glamar-fynd-835885/6Dzoxo/original/cxr_assets/2d/glasses/13/13_5.png",
              "https://cdn.pixelbin.io/v2/glamar-fynd-835885/6Dzoxo/original/cxr_assets/2d/glasses/13/13_6.png",
            ],
            created_at: "2023-10-19T13:44:45.316Z",
            updated_at: "2023-10-19T13:44:45.316Z",
          },
          {
            skuid: "b07ae46d-0122-408b-bf90-2c8e952eef36",
            categoryName: "glasses",
            assetType: "2d",
            assetIcon:
              "https://cdn.pixelbin.io/v2/glamar-fynd-835885/6Dzoxo/original/cxr_assets/icons/glasses/14.jpg",
            assets: [
              "https://cdn.pixelbin.io/v2/glamar-fynd-835885/6Dzoxo/original/cxr_assets/2d/glasses/14/14_1.png",
              "https://cdn.pixelbin.io/v2/glamar-fynd-835885/6Dzoxo/original/cxr_assets/2d/glasses/14/14_2.png",
              "https://cdn.pixelbin.io/v2/glamar-fynd-835885/6Dzoxo/original/cxr_assets/2d/glasses/14/14_3.png",
              "https://cdn.pixelbin.io/v2/glamar-fynd-835885/6Dzoxo/original/cxr_assets/2d/glasses/14/14_4.png",
              "https://cdn.pixelbin.io/v2/glamar-fynd-835885/6Dzoxo/original/cxr_assets/2d/glasses/14/14_5.png",
              "https://cdn.pixelbin.io/v2/glamar-fynd-835885/6Dzoxo/original/cxr_assets/2d/glasses/14/14_6.png",
            ],
            created_at: "2023-10-19T13:44:45.316Z",
            updated_at: "2023-10-19T13:44:45.316Z",
          },
        ],
      },
    ],
  },
  {
    categoryName: "bracelet",
    type: [
      {
        view: "2d",
        data: [
          {
            skuid: "efc76a3e-d9a2-49c2-b4e5-9bfb59e66a42",
            categoryName: "bracelet",
            assetType: "2d",
            assetIcon:
              "https://cdn.pixelbin.io/v2/glamar-fynd-835885/6Dzoxo/original/cxr_assets/icons/bracelet/01.jpg",
            assets: [
              "https://cdn.pixelbin.io/v2/glamar-fynd-835885/6Dzoxo/original/cxr_assets/2d/bracelet/01.png",
            ],
            created_at: "2023-10-19T13:44:45.316Z",
            updated_at: "2023-10-19T13:44:45.316Z",
          },
          {
            skuid: "31f5eab7-b54e-4cd9-a6da-dd9631232e7a",
            categoryName: "bracelet",
            assetType: "2d",
            assetIcon:
              "https://cdn.pixelbin.io/v2/glamar-fynd-835885/6Dzoxo/original/cxr_assets/icons/bracelet/02.jpg",
            assets: [
              "https://cdn.pixelbin.io/v2/glamar-fynd-835885/6Dzoxo/original/cxr_assets/2d/bracelet/02.png",
            ],
            created_at: "2023-10-19T13:44:45.316Z",
            updated_at: "2023-10-19T13:44:45.316Z",
          },
          {
            skuid: "136a6edd-a4fe-4ad4-9a1f-713d1e5ad148",
            categoryName: "bracelet",
            assetType: "2d",
            assetIcon:
              "https://cdn.pixelbin.io/v2/glamar-fynd-835885/6Dzoxo/original/cxr_assets/icons/bracelet/03.jpg",
            assets: [
              "https://cdn.pixelbin.io/v2/glamar-fynd-835885/6Dzoxo/original/cxr_assets/2d/bracelet/03.png",
            ],
            created_at: "2023-10-19T13:44:45.316Z",
            updated_at: "2023-10-19T13:44:45.316Z",
          },
          {
            skuid: "34a2829a-43c4-4e5d-b452-06438219e5d8",
            categoryName: "bracelet",
            assetType: "2d",
            assetIcon:
              "https://cdn.pixelbin.io/v2/glamar-fynd-835885/6Dzoxo/original/cxr_assets/icons/bracelet/04.jpg",
            assets: [
              "https://cdn.pixelbin.io/v2/glamar-fynd-835885/6Dzoxo/original/cxr_assets/2d/bracelet/04.png",
            ],
            created_at: "2023-10-19T13:44:45.316Z",
            updated_at: "2023-10-19T13:44:45.316Z",
          },
          {
            skuid: "d0c465d6-92cb-4fe1-9a12-445a17d35557",
            categoryName: "bracelet",
            assetType: "2d",
            assetIcon:
              "https://cdn.pixelbin.io/v2/glamar-fynd-835885/6Dzoxo/original/cxr_assets/icons/bracelet/05.jpg",
            assets: [
              "https://cdn.pixelbin.io/v2/glamar-fynd-835885/6Dzoxo/original/cxr_assets/2d/bracelet/05.png",
            ],
            created_at: "2023-10-19T13:44:45.316Z",
            updated_at: "2023-10-19T13:44:45.316Z",
          },
        ],
      },
    ],
  },
  {
    categoryName: "necklace",
    type: [
      {
        view: "2d",
        data: [
          {
            skuid: "9abdd352-d854-453e-a239-8b32e0dd3f5a",
            categoryName: "necklace",
            assetType: "2d",
            assetIcon:
              "https://cdn.pixelbin.io/v2/glamar-fynd-835885/6Dzoxo/original/cxr_assets/icons/necklace/01.jpg",
            assets: [
              "https://cdn.pixelbin.io/v2/glamar-fynd-835885/6Dzoxo/original/cxr_assets/2d/necklace/01.png",
            ],
            created_at: "2023-10-19T13:44:45.316Z",
            updated_at: "2023-10-19T13:44:45.316Z",
          },
          {
            skuid: "954ce3ac-cc27-49dd-ab30-5484188d80de",
            categoryName: "necklace",
            assetType: "2d",
            assetIcon:
              "https://cdn.pixelbin.io/v2/glamar-fynd-835885/6Dzoxo/original/cxr_assets/icons/necklace/02.jpg",
            assets: [
              "https://cdn.pixelbin.io/v2/glamar-fynd-835885/6Dzoxo/original/cxr_assets/2d/necklace/02.png",
            ],
            created_at: "2023-10-19T13:44:45.316Z",
            updated_at: "2023-10-19T13:44:45.316Z",
          },
          {
            skuid: "743790f8-9ace-4de1-8e5a-cd818aca95dc",
            categoryName: "necklace",
            assetType: "2d",
            assetIcon:
              "https://cdn.pixelbin.io/v2/glamar-fynd-835885/6Dzoxo/original/cxr_assets/icons/necklace/03.jpg",
            assets: [
              "https://cdn.pixelbin.io/v2/glamar-fynd-835885/6Dzoxo/original/cxr_assets/2d/necklace/03.png",
            ],
            created_at: "2023-10-19T13:44:45.316Z",
            updated_at: "2023-10-19T13:44:45.316Z",
          },
          {
            skuid: "157a0b55-f2d6-4e99-8156-16356b931074",
            categoryName: "necklace",
            assetType: "2d",
            assetIcon:
              "https://cdn.pixelbin.io/v2/glamar-fynd-835885/6Dzoxo/original/cxr_assets/icons/necklace/04.jpg",
            assets: [
              "https://cdn.pixelbin.io/v2/glamar-fynd-835885/6Dzoxo/original/cxr_assets/2d/necklace/04.png",
            ],
            created_at: "2023-10-19T13:44:45.316Z",
            updated_at: "2023-10-19T13:44:45.316Z",
          },
          {
            skuid: "8741c2cf-e5ac-4264-99bf-9833a4b26194",
            categoryName: "necklace",
            assetType: "2d",
            assetIcon:
              "https://cdn.pixelbin.io/v2/glamar-fynd-835885/6Dzoxo/original/cxr_assets/icons/necklace/05.jpg",
            assets: [
              "https://cdn.pixelbin.io/v2/glamar-fynd-835885/6Dzoxo/original/cxr_assets/2d/necklace/05.png",
            ],
            created_at: "2023-10-19T13:44:45.316Z",
            updated_at: "2023-10-19T13:44:45.316Z",
          },
        ],
      },
    ],
  },
  {
    categoryName: "watch",
    type: [
      {
        view: "3d",
        data: [
          {
            skuid: "3b273888-8be2-4450-90af-61a28fbab298",
            categoryName: "watch",
            assetType: "3d",
            assetIcon:
              "https://cdn.pixelbin.io/v2/glamar-fynd-835885/6Dzoxo/original/cxr_assets/icons/watch/10.jpg",
            assets: [
              "https://cdn.pixelbin.io/v2/glamar-fynd-835885/6Dzoxo/original/cxr_assets/watch/07.gltf",
            ],
            created_at: "2023-10-19T13:40:04.279Z",
            updated_at: "2023-10-19T13:40:04.279Z",
          },
          {
            skuid: "e8c84779-0b50-4b6c-954d-92d9d8b33a7b",
            categoryName: "watch",
            assetType: "3d",
            assetIcon:
              "https://cdn.pixelbin.io/v2/glamar-fynd-835885/6Dzoxo/original/cxr_assets/icons/watch/02.jpg",
            assets: [
              "https://cdn.pixelbin.io/v2/glamar-fynd-835885/6Dzoxo/original/cxr_assets/watch/02.gltf",
            ],
            created_at: "2023-10-19T13:43:23.870Z",
            updated_at: "2023-10-19T13:43:23.870Z",
          },
          {
            skuid: "bed2f2ee-d159-40b1-8b05-8addd2c6bf42",
            categoryName: "watch",
            assetType: "3d",
            assetIcon:
              "https://cdn.pixelbin.io/v2/glamar-fynd-835885/6Dzoxo/original/cxr_assets/icons/watch/03.jpg",
            assets: [
              "https://cdn.pixelbin.io/v2/glamar-fynd-835885/6Dzoxo/original/cxr_assets/watch/03.gltf",
            ],
            created_at: "2023-10-19T13:43:33.602Z",
            updated_at: "2023-10-19T13:43:33.602Z",
          },
          {
            skuid: "60bb29cf-b670-4c31-904d-9f673e680ecc",
            categoryName: "watch",
            assetType: "3d",
            assetIcon:
              "https://cdn.pixelbin.io/v2/glamar-fynd-835885/6Dzoxo/original/cxr_assets/icons/watch/04.jpg",
            assets: [
              "https://cdn.pixelbin.io/v2/glamar-fynd-835885/6Dzoxo/original/cxr_assets/watch/04.gltf",
            ],
            created_at: "2023-10-19T13:44:45.316Z",
            updated_at: "2023-10-19T13:44:45.316Z",
          },
          {
            skuid: "1b271e6c-1164-477b-9545-46295697708a",
            categoryName: "watch",
            assetType: "3d",
            assetIcon:
              "https://cdn.pixelbin.io/v2/glamar-fynd-835885/6Dzoxo/original/cxr_assets/icons/watch/05.jpg",
            assets: [
              "https://cdn.pixelbin.io/v2/glamar-fynd-835885/6Dzoxo/original/cxr_assets/watch/05.gltf",
            ],
            created_at: "2023-10-19T13:44:45.316Z",
            updated_at: "2023-10-19T13:44:45.316Z",
          },
        ],
      },
      {
        view: "2d",
        data: [
          {
            skuid: "3ce08f9e-d76e-44f3-8e72-b46e3088d6ec",
            categoryName: "watch",
            assetType: "2d",
            assetIcon:
              "https://cdn.pixelbin.io/v2/glamar-fynd-835885/6Dzoxo/original/cxr_assets/icons/watch/06.jpg",
            assets: [
              "https://cdn.pixelbin.io/v2/glamar-fynd-835885/6Dzoxo/original/cxr_assets/2d/watch/01.png",
            ],
            created_at: "2023-10-19T13:40:04.279Z",
            updated_at: "2023-10-19T13:40:04.279Z",
          },
          {
            skuid: "f662e9db-161d-489a-a2b6-e9b84ee72cda",
            categoryName: "watch",
            assetType: "2d",
            assetIcon:
              "https://cdn.pixelbin.io/v2/glamar-fynd-835885/6Dzoxo/original/cxr_assets/icons/watch/08.jpg",
            assets: [
              "https://cdn.pixelbin.io/v2/glamar-fynd-835885/6Dzoxo/original/cxr_assets/2d/watch/04.png",
            ],
            created_at: "2023-10-19T13:43:23.870Z",
            updated_at: "2023-10-19T13:43:23.870Z",
          },
          {
            skuid: "71b9d758-ec21-4ed7-83cd-855614154957",
            categoryName: "watch",
            assetType: "2d",
            assetIcon:
              "https://cdn.pixelbin.io/v2/glamar-fynd-835885/6Dzoxo/original/cxr_assets/icons/watch/07.jpg",
            assets: [
              "https://cdn.pixelbin.io/v2/glamar-fynd-835885/6Dzoxo/original/cxr_assets/2d/watch/02.png",
            ],
            created_at: "2023-10-19T13:43:33.602Z",
            updated_at: "2023-10-19T13:43:33.602Z",
          },
        ],
      },
    ],
  },
  {
    categoryName: "earring",
    type: [
      {
        view: "2d",
        data: [
          {
            skuid: "819d9f42-b0f1-4caa-84f4-cf9252001af1",
            categoryName: "earring",
            assetType: "2d",
            assetIcon:
              "https://cdn.pixelbin.io/v2/glamar-fynd-835885/6Dzoxo/original/cxr_assets/icons/earring/01.jpg",
            assets: [
              "https://cdn.pixelbin.io/v2/glamar-fynd-835885/6Dzoxo/original/cxr_assets/2d/earring/01.png",
            ],
            created_at: "2023-10-19T13:44:45.316Z",
            updated_at: "2023-10-19T13:44:45.316Z",
          },
          {
            skuid: "ee617094-b6f9-4b1b-8564-4c2757201fa3",
            categoryName: "earring",
            assetType: "2d",
            assetIcon:
              "https://cdn.pixelbin.io/v2/glamar-fynd-835885/6Dzoxo/original/cxr_assets/icons/earring/02.jpg",
            assets: [
              "https://cdn.pixelbin.io/v2/glamar-fynd-835885/6Dzoxo/original/cxr_assets/2d/earring/02.png",
            ],
            created_at: "2023-10-19T13:44:45.316Z",
            updated_at: "2023-10-19T13:44:45.316Z",
          },
          {
            skuid: "c4417920-c107-4137-bd89-5c1817f667b4",
            categoryName: "earring",
            assetType: "2d",
            assetIcon:
              "https://cdn.pixelbin.io/v2/glamar-fynd-835885/6Dzoxo/original/cxr_assets/icons/earring/03.jpg",
            assets: [
              "https://cdn.pixelbin.io/v2/glamar-fynd-835885/6Dzoxo/original/cxr_assets/2d/earring/03.png",
            ],
            created_at: "2023-10-19T13:44:45.316Z",
            updated_at: "2023-10-19T13:44:45.316Z",
          },
          {
            skuid: "28c3499a-d894-4556-908a-fc2a717c477b",
            categoryName: "earring",
            assetType: "2d",
            assetIcon:
              "https://cdn.pixelbin.io/v2/glamar-fynd-835885/6Dzoxo/original/cxr_assets/icons/earring/04.jpg",
            assets: [
              "https://cdn.pixelbin.io/v2/glamar-fynd-835885/6Dzoxo/original/cxr_assets/2d/earring/04.png",
            ],
            created_at: "2023-10-19T13:44:45.316Z",
            updated_at: "2023-10-19T13:44:45.316Z",
          },
          {
            skuid: "7ea9920d-8cf9-4c9d-a07c-ece0343f8f40",
            categoryName: "earring",
            assetType: "2d",
            assetIcon:
              "https://cdn.pixelbin.io/v2/glamar-fynd-835885/6Dzoxo/original/cxr_assets/icons/earring/05.jpg",
            assets: [
              "https://cdn.pixelbin.io/v2/glamar-fynd-835885/6Dzoxo/original/cxr_assets/2d/earring/05.png",
            ],
            created_at: "2023-10-19T13:44:45.316Z",
            updated_at: "2023-10-19T13:44:45.316Z",
          },
        ],
      },
    ],
  },
  {
    categoryName: "cap",
    type: [
      {
        view: "3d",
        data: [
          {
            skuid: "08335193-4113-4290-a8c9-2997cefa0918",
            categoryName: "cap",
            assetType: "3d",
            assetIcon:
              "https://cdn.pixelbin.io/v2/glamar-fynd-835885/6Dzoxo/original/cxr_assets/icons/cap/01.jpg",
            assets: [
              "https://cdn.pixelbin.io/v2/glamar-fynd-835885/6Dzoxo/original/cxr_assets/cap/01.gltf",
            ],
            created_at: "2023-10-19T13:40:04.279Z",
            updated_at: "2023-10-19T13:40:04.279Z",
          },
          {
            skuid: "15d6d3d1-416e-4df7-85d7-57aec13d7f0f",
            categoryName: "cap",
            assetType: "3d",
            assetIcon:
              "https://cdn.pixelbin.io/v2/glamar-fynd-835885/6Dzoxo/original/cxr_assets/icons/cap/02.jpg",
            assets: [
              "https://cdn.pixelbin.io/v2/glamar-fynd-835885/6Dzoxo/original/cxr_assets/cap/02.gltf",
            ],
            created_at: "2023-10-19T13:43:23.870Z",
            updated_at: "2023-10-19T13:43:23.870Z",
          },
          {
            skuid: "39f93db1-e3fb-44c0-ad98-62995db821e6",
            categoryName: "cap",
            assetType: "3d",
            assetIcon:
              "https://cdn.pixelbin.io/v2/glamar-fynd-835885/6Dzoxo/original/cxr_assets/icons/cap/03.jpg",
            assets: [
              "https://cdn.pixelbin.io/v2/glamar-fynd-835885/6Dzoxo/original/cxr_assets/cap/03.gltf",
            ],
            created_at: "2023-10-19T13:43:33.602Z",
            updated_at: "2023-10-19T13:43:33.602Z",
          },
          {
            skuid: "5cd65c78-ac41-4e99-84b5-22c5313d8ffd",
            categoryName: "cap",
            assetType: "3d",
            assetIcon:
              "https://cdn.pixelbin.io/v2/glamar-fynd-835885/6Dzoxo/original/cxr_assets/icons/cap/04.jpg",
            assets: [
              "https://cdn.pixelbin.io/v2/glamar-fynd-835885/6Dzoxo/original/cxr_assets/cap/04.gltf",
            ],
            created_at: "2023-10-19T13:44:45.316Z",
            updated_at: "2023-10-19T13:44:45.316Z",
          },
          {
            skuid: "4ff0bed3-798f-4411-9fa0-b5a1d17631e8",
            categoryName: "cap",
            assetType: "3d",
            assetIcon:
              "https://cdn.pixelbin.io/v2/glamar-fynd-835885/6Dzoxo/original/cxr_assets/icons/cap/05.jpg",
            assets: [
              "https://cdn.pixelbin.io/v2/glamar-fynd-835885/6Dzoxo/original/cxr_assets/cap/05.gltf",
            ],
            created_at: "2023-10-19T13:44:45.316Z",
            updated_at: "2023-10-19T13:44:45.316Z",
          },
        ],
      },
    ],
  },
  {
    categoryName: "mangtika",
    type: [
      {
        view: "2d",
        data: [
          {
            skuid: "fbd05ccb-7cbb-48ec-aa61-3b80785b9b49",
            categoryName: "mangtika",
            assetType: "2d",
            assetIcon:
              "https://cdn.pixelbin.io/v2/glamar-fynd-835885/6Dzoxo/original/cxr_assets/icons/mangtika/03.jpg",
            assets: [
              "https://cdn.pixelbin.io/v2/glamar-fynd-835885/6Dzoxo/original/cxr_assets/2d/mangtika/03.png",
            ],
            created_at: "2023-10-19T13:44:45.316Z",
            updated_at: "2023-10-19T13:44:45.316Z",
          },
          {
            skuid: "a1696675-31e7-4327-a191-6555875b5b3f",
            categoryName: "mangtika",
            assetType: "2d",
            assetIcon:
              "https://cdn.pixelbin.io/v2/glamar-fynd-835885/6Dzoxo/original/cxr_assets/icons/mangtika/01.jpg",
            assets: [
              "https://cdn.pixelbin.io/v2/glamar-fynd-835885/6Dzoxo/original/cxr_assets/2d/mangtika/01.png",
            ],
            created_at: "2023-10-19T13:44:45.316Z",
            updated_at: "2023-10-19T13:44:45.316Z",
          },
          {
            skuid: "6dbc9aa0-20e4-4037-9f75-0aaafdcfabfd",
            categoryName: "mangtika",
            assetType: "2d",
            assetIcon:
              "https://cdn.pixelbin.io/v2/glamar-fynd-835885/6Dzoxo/original/cxr_assets/icons/mangtika/02.jpg",
            assets: [
              "https://cdn.pixelbin.io/v2/glamar-fynd-835885/6Dzoxo/original/cxr_assets/2d/mangtika/02.png",
            ],
            created_at: "2023-10-19T13:44:45.316Z",
            updated_at: "2023-10-19T13:44:45.316Z",
          },
          {
            skuid: "7fb1a376-0a8d-4848-b19b-15262e7fa014",
            categoryName: "mangtika",
            assetType: "2d",
            assetIcon:
              "https://cdn.pixelbin.io/v2/glamar-fynd-835885/6Dzoxo/original/cxr_assets/icons/mangtika/04.jpg",
            assets: [
              "https://cdn.pixelbin.io/v2/glamar-fynd-835885/6Dzoxo/original/cxr_assets/2d/mangtika/04.png",
            ],
            created_at: "2023-10-19T13:44:45.316Z",
            updated_at: "2023-10-19T13:44:45.316Z",
          },
          {
            skuid: "a9a30647-8a12-4341-89d6-fd03c90b6295",
            categoryName: "mangtika",
            assetType: "2d",
            assetIcon:
              "https://cdn.pixelbin.io/v2/glamar-fynd-835885/6Dzoxo/original/cxr_assets/icons/mangtika/05.jpg",
            assets: [
              "https://cdn.pixelbin.io/v2/glamar-fynd-835885/6Dzoxo/original/cxr_assets/2d/mangtika/05.png",
            ],
            created_at: "2023-10-19T13:44:45.316Z",
            updated_at: "2023-10-19T13:44:45.316Z",
          },
        ],
      },
    ],
  },
  {
    categoryName: "ring",
    type: [
      {
        view: "2d",
        data: [
          {
            skuid: "b1c1304e-56b8-4887-912c-faaa34b0e163",
            categoryName: "ring",
            assetType: "2d",
            assetIcon:
              "https://cdn.pixelbin.io/v2/glamar-fynd-835885/6Dzoxo/original/cxr_assets/icons/ring/01.jpg",
            assets: [
              "https://cdn.pixelbin.io/v2/glamar-fynd-835885/6Dzoxo/original/cxr_assets/2d/ring/01.png",
            ],
            created_at: "2023-10-19T13:44:45.316Z",
            updated_at: "2023-10-19T13:44:45.316Z",
          },
          {
            skuid: "ded9bb22-7bec-4d06-a72c-7a1b7d467cae",
            categoryName: "ring",
            assetType: "2d",
            assetIcon:
              "https://cdn.pixelbin.io/v2/glamar-fynd-835885/6Dzoxo/original/cxr_assets/icons/ring/05.jpg",
            assets: [
              "https://cdn.pixelbin.io/v2/glamar-fynd-835885/6Dzoxo/original/cxr_assets/2d/ring/05.png",
            ],
            created_at: "2023-10-19T13:44:45.316Z",
            updated_at: "2023-10-19T13:44:45.316Z",
          },
          {
            skuid: "95dd50cd-3522-4d79-92f3-3934b0e47da2",
            categoryName: "ring",
            assetType: "2d",
            assetIcon:
              "https://cdn.pixelbin.io/v2/glamar-fynd-835885/6Dzoxo/original/cxr_assets/icons/ring/04.jpg",
            assets: [
              "https://cdn.pixelbin.io/v2/glamar-fynd-835885/6Dzoxo/original/cxr_assets/2d/ring/04.png",
            ],
            created_at: "2023-10-19T13:44:45.316Z",
            updated_at: "2023-10-19T13:44:45.316Z",
          },
          {
            skuid: "4f68d084-6ede-45cd-99f3-d0a1cb7f8ca5",
            categoryName: "ring",
            assetType: "2d",
            assetIcon:
              "https://cdn.pixelbin.io/v2/glamar-fynd-835885/6Dzoxo/original/cxr_assets/icons/ring/03.jpg",
            assets: [
              "https://cdn.pixelbin.io/v2/glamar-fynd-835885/6Dzoxo/original/cxr_assets/2d/ring/03.png",
            ],
            created_at: "2023-10-19T13:44:45.316Z",
            updated_at: "2023-10-19T13:44:45.316Z",
          },
          {
            skuid: "392918c6-a19b-4e88-b5a5-0a6044903422",
            categoryName: "ring",
            assetType: "2d",
            assetIcon:
              "https://cdn.pixelbin.io/v2/glamar-fynd-835885/6Dzoxo/original/cxr_assets/icons/ring/02.jpg",
            assets: [
              "https://cdn.pixelbin.io/v2/glamar-fynd-835885/6Dzoxo/original/cxr_assets/2d/ring/02.png",
            ],
            created_at: "2023-10-19T13:44:45.316Z",
            updated_at: "2023-10-19T13:44:45.316Z",
          },
        ],
      },
    ],
  },
];
