import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import IconButtons from "./IconButton";

import { sendScreenShotMessage } from "./sendData";
import { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import TypeButton from "./TypeButton";

const TypeButtonContainer = styled.div`
  padding: 5px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  margin-left: 100px;
`;

export default function TryOnIcons(props) {
  let slickSliderRef = useRef();
  const [currentIconIndex, setCurrentIconIndex] = useState(0);
  const [showCameraButton, setshowCameraButton] = useState(true);

  let handleBeforeIconChange = (current) => {
    setshowCameraButton(false);
  };

  let handleTryOnIconChange = (current) => {
    setCurrentIconIndex(current);
  };

  function handleTypeButtonClick(index) {
    props.setCurrentType(index);
  }

  const renderTypeButtons = () => {
    return props.api_data[props.stateValue]?.type.map((type, index) => (
      <TypeButton
        typeName={type.view}
        key={index}
        setIconsOrColors={() => handleTypeButtonClick(index)}
        currentButton={props.currentType}
        keyValue={index}
      />
    ));
  };

  useEffect(() => {
    console.log("Test icon slider changing");
    if (props.sdk_loaded_bool) {
      window.FyndStyle.applyMakeupBySku(
        props.api_data?.[props.stateValue]?.type?.[props.currentType].data?.[
          currentIconIndex
        ].skuid
      );
    }
  }, [currentIconIndex]);

  function afterTryOnItemStateChange() {
    if (currentIconIndex === 0) {
      console.log("Test op1");
      window.FyndStyle.applyMakeupBySku(
        props.api_data?.[props.stateValue]?.type?.[props.currentType].data?.[
          currentIconIndex
        ].skuid
      );

      setshowCameraButton(true);
    } else {
      console.log("Test op2");

      slickSliderRef.current.slickGoTo(0);

      handleTryOnIconChange(0);
    }
  }

  const settings = {
    variableWidth: true,
    focusOnSelect: true,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 150,
    arrows: false,
    cssEase: "linear",
    swipeToSlide: true,
    touchThreshold: 20,
    beforeChange: handleBeforeIconChange,
    afterChange: handleTryOnIconChange,
  };

  useEffect(() => {
    afterTryOnItemStateChange();
  }, [props.stateValue, props.currentType]);

  console.log("VALUE current icon index", currentIconIndex);
  console.log("VALUE  state   index", props.stateValue);
  console.log("VALUE  current type index", props.currentType);

  return (
    <>
      <div id="icon-slider-div">
        <img
          className="icon-center-dash"
          src="./images/rectangle.png"
          alt="rectangle"
        />

        {/* <div className="icon-center-camera-div">
          <div className="icon-center-camera-background">
            <img
              className="icon-center-camera"
              src="./images/camera.png"
              alt="camera "
              onClick={sendScreenShotMessage}
            />
          </div>
        </div> */}

        <div className="icon-blur-background"></div>

        <Slider {...settings} ref={slickSliderRef}>
          {props.api_data?.[props.stateValue]?.type?.[
            props.currentType
          ].data?.map((icons, index) => (
            <IconButtons
              currentIndex={currentIconIndex}
              selfIndex={index}
              key={index}
              imgUrl={icons.assetIcon}
              showCameraButton={showCameraButton}
            />
          ))}
        </Slider>
      </div>
      <TypeButtonContainer>{renderTypeButtons()}</TypeButtonContainer>
    </>
  );
}
// }
