import TryOnSlider from "./Components/TryOnCarousel";
import "./App.css";
import { useEffect, useState } from "react";
import { dummyData } from "./dummyJsonData";
import styled from "styled-components";
import { Provider, useDispatch, useSelector } from "react-redux";
import environment from "./env";

const UIWrapper = styled.div`
  height: 100%;
  flex-direction: column;
  justify-content: flex-end;
  display: ${(props) => (props.show ? "flex" : "none")};
`;

const FyndStyleSdkWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 100;
  bottom: 100;
  border: 0;
`;

function App() {
  const [isUiSliderState] = useSelector((state) => [
    state.sdkTabsData.isUiSliderState,
  ]);

  const [customData, setcustomData] = useState([]);
  let [isSdkLoaded, setIsSdkLoaded] = useState(false);
  const isWindowAvailable = () => {
    return typeof window !== "undefined";
  };
  const isMobile = isWindowAvailable() && window.innerWidth < 768;

  useEffect(() => {
    setcustomData(dummyData);
    window.FyndStyle.init(
      "fynd-style-wrapper",
      "62a16d1e-0103-4b73-9a07-57b55aeb6277",
      {
        mode: "private",
        platform: "web",
      }
    );

    window.FyndStyle.addEventListener("*", (e) => {
      switch (e) {
        case "loaded":
          // window.FyndStyle.loadDemo(isMobile ? 230 : 395);
          setIsSdkLoaded(true);
          window.FyndStyle.applyMakeupBySku(dummyData[0].type[0].data[0].skuid);

          break;
        case "loading":
          break;
        case "sku-applied":
          break;
        case "sku-failed":
          break;
        default:
          console.log("na");
      }
    });
  }, []);

  return (
    <>
      <FyndStyleSdkWrapper id="fynd-style-wrapper" />

      <UIWrapper show={isUiSliderState}>
        <TryOnSlider api_data={customData} is_sdk_loaded={isSdkLoaded} />
      </UIWrapper>
    </>
  );
}

export default App;
