import React from "react";
import styled from "styled-components";

const IconButtonContainer = styled.div`
  position: relative;
  height: 64px;
  width: 64px;
  border-radius: 64px;
  background-size: cover;
  /* background-color: ${({ color }) => color || "transparent"}; */
  /* background-blend-mode: multiply; */
  @media (max-width: 768px) {
    height: 40px;
    width: 40px;
    border-radius: 40px;
  }
`;
const IconImage = styled.img`
  height: 64px;
  width: 64px;
  border-radius: 64px;
  @media (max-width: 768px) {
    height: 40px;
    width: 40px;
    border-radius: 40px;
  }
`;

export default function IconButtons({ imgUrl }) {
  return (
    <IconButtonContainer>
      <IconImage src={imgUrl} />
    </IconButtonContainer>
  );
}
