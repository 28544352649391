import React, { useState, useRef } from "react";
import Slider from "react-slick";
import TryOnItem from "./OptionButton";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import TryOnIcons from "./IconSlider";

export default function TryOnSlider(props) {
  const settings = {
    variableWidth: true,
    focusOnSelect: true,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    cssEase: "linear",
    swipeToSlide: true,

    touchThreshold: 20,

    speed: 150,
    afterChange: afterChangeOptionName,
  };
  const sliderRef = useRef();
  const [user, setUser] = useState(0);
  const [currentType, setCurrentType] = useState(0);

  function afterChangeOptionName(current) {
    console.log("Test bottom slider changes");
    setUser(current);
    // sliderRef.current.slickGoTo(1);
    setCurrentType(0);
  }

  return (
    <div id="try-on-carousel">
      <TryOnIcons
        stateValue={user}
        api_data={props.api_data}
        sdk_loaded_bool={props.is_sdk_loaded}
        currentType={currentType}
        setCurrentType={setCurrentType}
      />

      <div id="option-slider-div">
        <Slider {...settings} ref={sliderRef}>
          {props.api_data.map((eachvalue, index) => {
            return (
              <TryOnItem
                key={index}
                name={eachvalue.categoryName.toUpperCase()}
              />
            );
          })}
        </Slider>
        <h1 className="dot">.</h1>
      </div>
    </div>
  );
}
// }
